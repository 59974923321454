import { BaseEntity } from "./base/base-entity";

// usuarios registrados
export class UserIpt extends BaseEntity<UserIpt> {
  id: number;
  fullName: string;
  email: string;
  createAt: string;
}

export class UserPermission extends BaseEntity<UserPermission> {
  permissionId?: number;
  userId?: number;
  application_id?: number;
  permission_name?: string;
  application_name?: string;
  checked?: boolean;
}
