import {
	MsalGuardConfiguration,
	MsalInterceptorConfiguration,
} from "@azure/msal-angular";
import {
	BrowserCacheLocation,
	InteractionType,
	IPublicClientApplication,
	LogLevel,
	PublicClientApplication,
} from "@azure/msal-browser";
import { environment } from "src/environments/environment";
import {
	AZURE_URI_POST_LOGOUT_REDIRECT_TO,
	AZURE_URI_REDIRECT_TO,
	AZURE_PATH_LOGIN_FAILURE,
} from "./routes";

export const isIE =
	window.navigator.userAgent.indexOf("MSIE ") > -1 ||
	window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
	// console.log(message, "loggerCallback");
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.AZURE_CLIENT_ID,
			authority: environment.AZURE_AUTHORITY,
			redirectUri: AZURE_URI_REDIRECT_TO,
			postLogoutRedirectUri: AZURE_URI_POST_LOGOUT_REDIRECT_TO,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE,
		},
		system: {
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false,
			},
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();

	protectedResourceMap.set("https://graph.microsoft-ppe.com/v1.0/me", [
		"user.read",
	]);

	protectedResourceMap.set(`${environment.urlBackEndDashboardIpt}/*`, [
		environment.ambitoBackEndDashboardIpt,
	]);
	protectedResourceMap.set(`${environment.urlBackEndBotTelegram}/*`, [
		environment.ambitoBackEndBotTelegram,
	]);
	protectedResourceMap.set(`${environment.urlBackendOCR}/*`, ["user.read"]);

	if (environment.urlBackendCapacitacionTecnicos) {
		protectedResourceMap.set(
			`${environment.urlBackendCapacitacionTecnicos}/*`,
			["user.read"]
		);
		protectedResourceMap.set(`${environment.urlEventosCampos}/*`, [
			"user.read",
		]);
	}

	// Allowed Endpoints Perimeters
	protectedResourceMap.set(`${environment.urlBackendInventory}/*`, [
		"user.read",
	]);

	protectedResourceMap.set(`${environment.urlBackendPortal}/*`, [
		"user.read",
	]);

	protectedResourceMap.set(`${environment.urlBackendPolicies}/*`, [
		"user.read",
	]);

	protectedResourceMap.set(`${environment.urlBackendFlujoBajas}/*`, [
		"user.read",
	]);

	// unprotected endpoints initial tunning
	protectedResourceMap.set(
		`${environment.urlBackendITuning}/api/sites/get_validation_form_item/`,
		null
	);
	protectedResourceMap.set(
		`${environment.urlBackendITuning}/api/sites/confirm_and_reject_sites/`,
		null
	);
	// end unprotected endpoints initial tunning

	protectedResourceMap.set(`${environment.urlBackendITuning}/*`, [
		"user.read",
	]);

	// protectedResourceMap.set(`${window.location.origin}/*`, ["user.read"]);

	// unprotected endpoints integration
	protectedResourceMap.set(`${environment.urlBackendIntegration}/*`, [
		"faf3500c-1b01-4260-8e2e-91f96a79a37c/ipt-pipt-api-integraciondespliegue",
	]);

	// unprotected endpoints licenses
	protectedResourceMap.set(
		`${environment.urlBackendLicensesMarcacionesNOC}/*`,
		[
			`${environment.AZURE_CLIENT_ID_CATALOGO_LICENCIAS}/${environment.CATALOGO_LICENCIAS_APP_NAME}`,
		]
	);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ["user.read"],
		},
		loginFailedRoute: AZURE_PATH_LOGIN_FAILURE,
	};
}
