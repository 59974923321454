import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// azure
import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from "@azure/msal-browser";

// guards
import { MustBeAzureAuthenticate, MustBeUnregister } from "./core/guards";

import {
	SLUG_DASHBOARD_IPT,
	SLUG_GESTION_POLITICAS,
	SLUG_LOGIN,
	SLUG_PORTAL,
	PATH_REDIRECT_TO,
	SLUG_INITIAL_TUNING,
	SLUG_SITE_ACCEPTANCE,
	SLUG_INVENTORY,
	SLUG_PROCESAR_RECIBOS,
	SLUG_INTEGRATION,
	SLUG_LOW_FLOW,
	SLUG_AUTO_CORE,
	SLUG_CAPACITACION_TECNICOS,
	SLUG_LICENSES,
} from "./routes";

const routes: Routes = [
	{
		path: SLUG_LOGIN,
		loadChildren: () =>
			import("./auth/auth.module").then((m) => m.AuthModule),
		canActivate: [MustBeUnregister],
	},
	{
		path: SLUG_PORTAL,
		canActivate: [MsalGuard, MustBeAzureAuthenticate],
		loadChildren: () =>
			import("./portal/portal.module").then((m) => m.PortalModule),
	},
	{
		path: SLUG_DASHBOARD_IPT,
		canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./dashboard-ipt/dashboard-ipt.module").then(
				(m) => m.DashboardIptModule
			),
	},
	{
		path: SLUG_GESTION_POLITICAS,
		canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./policy-manage/policy-manage.module").then(
				(m) => m.PolicyManageModule
			),
	},
	{
		path: SLUG_INITIAL_TUNING,
		canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./initial-tuning/initial-tuning.module").then(
				(m) => m.InitialTuningModule
			),
	},
	{
		path: SLUG_LICENSES,
		canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./licenses/licenses.module").then((m) => m.LicensesModule),
	},
	{
		path: SLUG_CAPACITACION_TECNICOS,
		canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./capacitacion-tecnicos/capacitacion-tecnicos.module").then(
				(m) => m.CapacitacionTecnicosModule
			),
	},
	{
		path: SLUG_INVENTORY,
		canActivate: [],
		loadChildren: () =>
			import("./inventory/inventory.module").then(
				(m) => m.InventoryModule
			),
	},
	{
		path: SLUG_PROCESAR_RECIBOS,
		canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./procesar-recibos-luz/procesar-recibos-luz.module").then(
				(m) => m.ProcesarRecibosLuzModule
			),
	},
	{
		path: SLUG_INTEGRATION,
		canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./integration/integration.module").then(
				(m) => m.IntegrationModule
			),
	},
	{
		path: "bot-telegram",
		// canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./bot-telegram/bot-telegram.module").then(
				(m) => m.BotTelegramModule
			),
	},
	{
		path: SLUG_SITE_ACCEPTANCE,
		loadChildren: () =>
			import("./site-acceptance/site-acceptance.module").then(
				(m) => m.SiteAcceptanceModule
			),
	},
	{
		path: SLUG_LOW_FLOW,
		canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./flow/flow.module").then((m) => m.FlowModule),
	},
	{
		path: SLUG_AUTO_CORE,
		canActivate: [MustBeAzureAuthenticate, MsalGuard],
		loadChildren: () =>
			import("./automation-core/automation-core.module").then(
				(m) => m.AutomationCoreModule
			),
	},
	{ path: "", pathMatch: "full", redirectTo: PATH_REDIRECT_TO },
	{
		path: "**",
		redirectTo: PATH_REDIRECT_TO,
		pathMatch: "full",
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			relativeLinkResolution: "legacy",
			initialNavigation:
				!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
					? "enabled"
					: "disabled",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
