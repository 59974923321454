<div *ngIf="message && message.length" class="list-messages" [ngClass]="getClasses()">
  <div class="list-messages__head">
    <div class="list-messages__title">
      <mat-icon class="mr-2">error_outline</mat-icon>
      <span>
        {{ title }}
      </span>
    </div>
    <ng-container *ngIf="close">
      <button mat-icon-button class="list-messages__btn-close" (click)="handleClose()">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>
  </div>
  <ng-container *ngIf="isList; else messageString">
    <ul>
      <li *ngFor="let error of message">
        {{ error }}
      </li>
    </ul>
  </ng-container>

  <ng-template #messageString>
    <p class="list-messages__message">{{ message}}</p>
  </ng-template>
</div>
