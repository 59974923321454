<div [ngClass]="['ui-alert', 'ui-alert--' + typeAlert]">

  <div class="ui-alert__header">
    <div class="ui-alert__header-left">
      <h3 class="ui-alert__title">{{ title }}</h3>
    </div>
    <button mat-icon-button class="ui-alert__btn-close" matTooltip="Cerrar" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="ui-alert__body">

    <list-messages type="error" [message]="errors"></list-messages>

    <div class="text-center mb-3 ui-alert__icon-type">
      <mat-icon class="icon-size-lg ui-alert__icon-type">{{icon}}</mat-icon>
    </div>

    <p *ngIf="data.nameResource">
      Recurso: <strong class="text-primary">{{ data.nameResource }}</strong>
    </p>
    <p class="text-color text-center">
      {{message}}
    </p>
  </div>
  <div class="ui-alert__footer ui-alert__footer--end">
    <button mat-flat-button class="ui-alert__btn-cancel" mat-dialog-close>CANCELAR</button>
    <button [loading]="loading" [disabled]="loading" mat-flat-button class="ui-alert__btn-accept" cdkFocusInitial
      (click)="onOk()">ACEPTAR</button>
  </div>
</div>
