import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanLoad,
	Router,
	RouterStateSnapshot,
} from "@angular/router";

import { Observable, interval, of } from "rxjs";
import { delay, switchMap, take } from "rxjs/operators";
import { UserPermitsService } from "../services";
import { AuthService } from "@app/core/services";

/**
 * Obtiene y guarda todos los permisos del usuario en un servicio,
 * Los permisos se guardar como string ['PERM1', 'PERM2']
 * y como objeto [{key:'PERM1',value:'Permiso 1}]
 */

@Injectable({ providedIn: "root" })
export class MustGetPermitsToModule implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthService,
		private userPermitsService: UserPermitsService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.hasUserPermission();
	}

	hasUserPermission() {
		return this.userPermitsService.findPermissionByUserLogged().pipe(
			switchMap((response) => {
				this.authService.setUserPermissions(
					response.data.map(({ name }) => name)
				);

				// guardar todos los permisos retornados del usuario de API Permissions
				this.authService.setUserPermissionsAsObject(response.data);

				return of(true);
			})
		);
	}
}
