export const environment = {
	production: false,
	urlBackEndDashboardIpt: "http://34.139.202.84:8082",
	ambitoBackEndDashboardIpt:
		"api://d231c12d-2150-43e7-87ac-c54ff1b69e2a/backend-dashboard",
	urlBackEndBotTelegram: "https://backend-bot-dev.ipt.pe",
	ambitoBackEndBotTelegram:
		"api://e8bcb153-8131-4d62-bbbd-e5d60da8bbf5/bot-telegram-api",
	ambitoBackEndOCR: "user.read",
	AZURE_CLIENT_ID: "19769e0f-700c-4a0e-b040-1a13a0acf312",
	AZURE_AUTHORITY:
		"https://login.microsoftonline.com/internetparatodospe.onmicrosoft.com",
	urlClientApp: "https://portal-dev.ipt.pe",
	slugBackend: "api",
	urlBackendPortal: "https://authorization-api-dev.ipt.pe",
	urlBackendPolicies: "https://gestion-politicas-calidad-dev.ipt.pe",
	urlBackendITuning: "https://initial-tuning-dev.ipt.pe",
	urlBackendInventory: "https://inventario-sites-dev.ipt.pe",
	urlBackendAlarmsEvents: "https://alarmas-eventos-dev.ipt.pe",
	urlBackendOCR: "https://ocr-api-dev.ipt.pe",
	wsUrlInventaryNotification: "wss://inventario-sites-dev.ipt.pe",
	urlBackendIntegration: "https://api-integraciondespliegue-dev.ipt.pe",
	urlBackendFlujoBajas: "https://core-automatizaciones-dev.ipt.pe",
	KEY_GOOGLE_MAP: "AIzaSyCbrsnuFkGOiLWHGXQkHkHOYXfgSY7ACHs",
	urlBackendCapacitacionTecnicos: "https://evaluaciones-ssoma-dev.ipt.pe",
	urlBackendLicensesMarcacionesNOC: "https://marcacionesnoc-back-dev.ipt.pe",
	AZURE_CLIENT_ID_CATALOGO_LICENCIAS: "90ee5fb5-982d-45b7-bcd6-ec858efd433c",
	CATALOGO_LICENCIAS_APP_NAME: "ipt-pipt-marcacionescatalogo-back-dev",
	urlEventosCampos: "https://eventos-campos-dev.ipt.pe",
};
