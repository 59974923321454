import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioComponent } from './usuario/usuario.component';
import { CrearUsuarioComponent } from './usuario/crear-usuario/crear-usuario.component';
import { MaterialModule } from '../material/material.module'

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BotTelegramRoutingModule } from './bot-telegram-routing.module';
import { HeaderBotTelegramComponent } from './layout/header-bot-telegram/header-bot-telegram.component';
import { LayoutBotTelegramComponent } from './layout/layout-bot-telegram/layout-bot-telegram.component';
import { HomeBotTelegramComponent } from './components/home-bot-telegram/home-bot-telegram.component';


@NgModule({
  declarations: [
    UsuarioComponent,
    CrearUsuarioComponent,
    HeaderBotTelegramComponent,
    LayoutBotTelegramComponent,
    HomeBotTelegramComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    BotTelegramRoutingModule
  ],
  exports: [
  ]
})
export class BotTelegramModule { }
