<div class="panel-container">
  <!-- Panel -->
  <mat-accordion>
    <mat-expansion-panel [expanded]="panelVer" [disabled]="!panelVer" >
      <!-- Panel Header -->
      <mat-expansion-panel-header>
        <mat-panel-title >
          Ver usuarios
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- Panel content -->
      <mat-card class="title-card">
        <span class="title">LISTA DE USUARIOS</span>
        <span class="spacer"></span>
        <button mat-raised-button color="primary" (click)="crear(true)">Crear Usuario</button>
      </mat-card>
      <div class="container">
        <!-- Empty box -->
        <div *ngIf=" listUsuarios.length == 0">
          <mat-card class="empty-box">
            <h2>Parece que no contamos con usuarios registrados</h2>
          </mat-card>
        </div>
        <!-- List of elements -->
        <div>
          <!-- Filter -->
          <mat-form-field appearance="standard">
            <mat-label>Búsqueda</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Nombre Apellido" #input autocomplete="off">
          </mat-form-field>

          <div class="mat-elevation-z5">
            <!-- Table -->
            <table mat-table [dataSource]="dataSource" matSort>

              <!-- Columns -->

              <!-- Id Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>

              <!-- First Name Column -->
              <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
              </ng-container>

              <!-- Last Name Column -->
              <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef> Apellido </th>
                <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
              </ng-container>

              <!-- Role Column -->
              <ng-container matColumnDef="rol">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rol </th>
                <td mat-cell *matCellDef="let element"> {{element.rol.nombre}} </td>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="correo">
                <th mat-header-cell *matHeaderCellDef> Correo </th>
                <td mat-cell *matCellDef="let element"> {{element.correo}} </td>
              </ng-container>

              <!-- Edit Column -->
              <ng-container matColumnDef="editar">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" style="text-align: center; color: var(--color-ipt-secondary-bold);">
                  <button mat-icon-button matTooltip="Editar usuario" (click)="editarUsuarioButton(element)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </ng-container>
              <!-- Delete Column -->
              <ng-container matColumnDef="eliminar">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" style="text-align: center; color: var(--color-portal-warm);">
                  <button mat-icon-button matTooltip="Borrar usuario" (click)="borrarUsuarioButton(element)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <!-- Paginator -->

            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of users">
            </mat-paginator>
          </div>
        </div>
        
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="panelCrear" [disabled]="!panelCrear">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Crear o Editar Usuario
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-crear-usuario (clickButton)="listar($event)" [usuario]="usuario"></app-crear-usuario>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
