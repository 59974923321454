import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

type theme = "default" | "success" | "info" | "warning" | "error";

@Component({
	selector: "list-messages",
	templateUrl: "./list-messages.component.html",
	styleUrls: ["./list-messages.component.scss"],
})
export class ListMessagesComponent implements OnInit {
	// title message
	@Input() title: string = "Errores encontrados";

	// messages list
	@Input() message: string[] | string;

	// add class to parent
	@Input() classes: string = "";

	// add button close
	@Input() close: boolean = false;

	// set theme message
	@Input() type: theme = "default";

	// Emit event onClose when click button close
	@Output() onClose = new EventEmitter<any>();

	messageTemp;
	isList: boolean = false;

	constructor() {}

	ngOnInit(): void {
		this.isList = Array.isArray(this.message);
	}

	handleClose() {
		this.onClose.emit();
	}

	getClasses() {
		const classNamesList = [];

		if (typeof this.classes === "string" && this.classes.trim().length > 0)
			classNamesList.push(this.classes);

		if (this.type !== "default") classNamesList.push(`is-${this.type}`);

		return classNamesList.join(" ");
	}
}
