import { HotToastModule } from "@ngneat/hot-toast";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { BotTelegramModule } from "./bot-telegram/bot-telegram.module";
import "./routes";

// azure
import {
	MsalBroadcastService,
	MsalGuard,
	MsalInterceptor,
	MsalModule,
	MsalRedirectComponent,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
	MSALGuardConfigFactory,
	MSALInstanceFactory,
	MSALInterceptorConfigFactory,
} from "./azure-ad";

registerLocaleData(localeEs, "es");

const providersAzure = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: MsalInterceptor,
		multi: true,
	},
	{
		provide: MSAL_INSTANCE,
		useFactory: MSALInstanceFactory,
	},
	{
		provide: MSAL_GUARD_CONFIG,
		useFactory: MSALGuardConfigFactory,
	},
	{
		provide: MSAL_INTERCEPTOR_CONFIG,
		useFactory: MSALInterceptorConfigFactory,
	},
	MsalService,
	MsalGuard,
	MsalBroadcastService,
];

// providers 'es-ES'

@NgModule({
	declarations: [AppComponent],
	imports: [
		BotTelegramModule,
		// MaterialModule,
		BrowserAnimationsModule,
		HttpClientModule,
		BrowserModule,
		AppRoutingModule,
		MsalModule,
		HotToastModule.forRoot({
			loading: {
				duration: 999999,
			},
		}),
	],
	providers: [
		...providersAzure,
		{ provide: LOCALE_ID, useValue: "es-Es" },
		// MustBeAuthenticate,
		// MustBeUnregister,
	], // LoginGuard, MustBeAuthenticate
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

``;
