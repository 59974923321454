import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BotUsuario } from 'src/app/bot-telegram/core/models/BotUsuario';
import { BackEndBotService } from 'src/app/bot-telegram/core/services/back-end-bot.service';

@Component({
  selector: 'app-crear-usuario',
  templateUrl: './crear-usuario.component.html',
  styleUrls: ['./crear-usuario.component.scss']
})
export class CrearUsuarioComponent implements OnInit {

   // selector
   roles: any[] = [
    { value: 1, viewValue: 'ADMIN' },
    { value: 2, viewValue: 'ANALISTA' },
    { value: 3, viewValue: 'OPERARIO OyM' },
    { value: 4, viewValue: 'OPERARIO DESPLIEGUE' },
  ];

  selected: any;
  titulo: string;

  @Input() usuario: BotUsuario;

  form = this.fb.group({
    idUser: ['', [Validators.required, Validators.minLength(8), Validators.pattern("^[0-9]*$")]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    rol: ['', Validators.required],
    correo: ['', [Validators.required, Validators.email]],
  })


  constructor(private fb: FormBuilder, private router: Router,
    private _snackBar: MatSnackBar, private _backEndBotService: BackEndBotService,
    private aRoute: ActivatedRoute) {  }

  ngOnInit(): void {
    this.esEditar();
  }

  ngOnChanges(){
    this.esEditar();
  }

  // Acciones

  agregarUsuarioButton() {
    if (this.usuario.id === undefined) {
      const user: BotUsuario = {
        id: this.form.value.idUser,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        rol: {
          id: this.form.value.rol,
          nombre: '',
        },
        correo: this.form.value.correo
      }
      this.agregarUsuario(user);
    } else {
      const user: BotUsuario = {
        id: this.usuario.id,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        rol: {
          id: this.form.value.rol,
          nombre: '',
        },
        correo: this.form.value.correo
      }
      this.editarUsuario(user);
    }
  }

  agregarUsuario(user: BotUsuario) {
    this._backEndBotService.agregarUsuario(user).subscribe(result => {
      this.gotoUserList();
      this.snackMsg('Usuario creado con éxito');
    });
  }

  editarUsuario(user: BotUsuario) {
    this._backEndBotService.editarUsuario(user).subscribe(result => {
      this.form.reset;
      this.usuario = new BotUsuario;
      this.snackMsg('Usuario editado con éxito');
      this.gotoUserList();

    })
  }

  esEditar() {
    if (this.usuario.id !== undefined) {
      this.titulo = 'Editar Usuario'.toLocaleUpperCase();
      // seleccionar rol
      this.selected = this.usuario.rol.id;

      // inicializar el formulario
      this.form.setValue({
        idUser: this.usuario.id,
        firstName: this.usuario.firstName,
        lastName: this.usuario.lastName,
        rol: this.selected,
        correo: this.usuario.correo
      })
      this.form.controls['idUser'].disable();

    }else {
      this.titulo = 'Crear Usuario'.toLocaleUpperCase();
      this.form.controls['idUser'].enable();
      this.form.reset();
    }
  }

  // Funciones extra

  // Eviar data al componente padre
  @Output() clickButton = new EventEmitter<boolean>();

  gotoUserList() {
    this.clickButton.emit(true);
  }

  cancelar(panelVer: boolean) {
    this.usuario = new BotUsuario();
    this.clickButton.emit(panelVer);
  }

  // Snack messages
  snackMsg(message: string) {
    this._snackBar.open(message, '', {
      duration: 4000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    })
  }

}
