import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from "@angular/router";
import { MsalService } from "@azure/msal-angular";

import { Observable, of } from "rxjs";
import { PATH_REDIRECT_TO } from "../../routes";
import { AuthService } from "../services";
import { TokenStorageService } from "../services/token-storage.service";
@Injectable({ providedIn: "root" })
export class MustBeUnregister implements CanActivate {
	constructor(
		private msalService: MsalService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const accounts = this.msalService.instance.getAllAccounts();

		if (!accounts.length) {
			return of(true);
		}

		this.router.navigate([PATH_REDIRECT_TO]);
		return of(false);
	}
}
