import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NotificationComponent } from "./notification.component";

interface INotifySnackbar
	extends Omit<MatSnackBarConfig, "data" | "panelClass"> {
	message?: string;
	className?: string;
}

const CONFIG_SNACKBAR_DEFAULT: MatSnackBarConfig = {
	duration: 3000,
	verticalPosition: "top",
	horizontalPosition: "right",
};

type TYPE_NOTIFY = "ERROR" | "SUCCESS" | "INFO" | "WARNING";
const MESSAGE_DEFAULT = {
	ERROR: "Oops! Ha ocurrido un error!. Inténtelo en otro momento!",
	SUCCESS: "SUCCESS",
	INFO: "INFO",
	WARNING: "WARNING",
};

const ICON = {
	ERROR: "error_outline",
	SUCCESS: "check_circle",
	WARNING: "warning",
};

@Injectable({
	providedIn: "root",
})
export class NotificationService {
	constructor(private _snackBar: MatSnackBar) {}

	private openSnackbar(
		type: TYPE_NOTIFY,
		notifyConfig?: INotifySnackbar
	): void {
		let styles = [
			"ui-message",
			"ui-notify",
			`ui-notify--${type.toLowerCase()}`,
			"shadow-none",
		];

		if (notifyConfig?.className) {
			styles = styles.concat(notifyConfig.className.split(" "));
		}

		const settings: MatSnackBarConfig = {
			...CONFIG_SNACKBAR_DEFAULT,
			...notifyConfig,
			data: {
				message: notifyConfig?.message || MESSAGE_DEFAULT[type],
				icon: ICON[type],
			},
			panelClass: styles,
		};
		this._snackBar.openFromComponent(NotificationComponent, settings);
	}

	public error(notifyConfig?: INotifySnackbar): void {
		this.openSnackbar("ERROR", notifyConfig);
	}

	public success(notifyConfig?: INotifySnackbar): void {
		this.openSnackbar("SUCCESS", notifyConfig);
	}

	public info(notifyConfig?: INotifySnackbar): void {
		this.openSnackbar("INFO", notifyConfig);
	}

	public warning(notifyConfig?: INotifySnackbar): void {
		this.openSnackbar("WARNING", notifyConfig);
	}
}
