import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
	isIframe = false;

	constructor(
		private iconRegistry: MatIconRegistry,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.isIframe = window !== window.parent && !window.opener;
		this.addCustomIcons();
	}

	private addCustomIcons(): void {
		this.registerSvgIcon(
			"p-arrow-chevron-right",
			"../assets/icons/chevron-right.svg"
		);
		this.registerSvgIcon("p-search", "../assets/icons/search.svg");
		this.registerSvgIcon("p-bell", "../assets/icons/notificaciones.svg");
		this.registerSvgIcon("p-home", "../assets/icons/home.svg");
		this.registerSvgIcon("p-permissions", "../assets/icons/permisos.svg");
		this.registerSvgIcon("p-news", "../assets/icons/comunicados.svg");
		this.registerSvgIcon("p-apps", "../assets/icons/aplicaciones.svg");
		this.registerSvgIcon("table-plus", "../assets/icons/table-plus.svg");
		this.registerSvgIcon("table-minus", "../assets/icons/table-minus.svg");
		this.registerSvgIcon("layout-columns", "../assets/icons/layout-columns.svg");
		this.registerSvgIcon("filter-plus", "../assets/icons/filter-plus.svg");
		this.registerSvgIcon("p-download", "../assets/icons/download.svg");
	}

	private registerSvgIcon(name: string, relativePath: string) {
		this.iconRegistry.addSvgIcon(
			name,
			this.sanitizer.bypassSecurityTrustResourceUrl(relativePath)
		);
	}
}
