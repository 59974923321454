import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

/*
 * agrega un loading al boton de angular 'mat-button'
 */

@Directive({
  selector: "button",
  providers: [MatButton], // Revisar si es necesario colocarlo aqui o en sharedModule
})
export class LoadingDirective implements OnChanges {
  private spinner!: ComponentRef<MatProgressSpinner> | null;

  @Input() loading = false;

  constructor(
    private matButton: MatButton,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private cfResolver: ComponentFactoryResolver
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.loading) {
      return;
    }

    if (changes.loading.currentValue) {
      this.matButton._elementRef.nativeElement.classList.add("button-loading");
      this.matButton.disabled = true;
      this.createSpinner();
    } else if (!changes.loading.firstChange) {
      this.matButton._elementRef.nativeElement.classList.remove(
        "button-loading"
      );
      this.destroySpinner();
    }
  }

  private createSpinner(): void {
    if (!this.spinner) {
      const spinnerResolver =
        this.cfResolver.resolveComponentFactory(MatProgressSpinner);
      this.spinner = this.viewContainerRef.createComponent(spinnerResolver);
      this.spinner.instance.diameter = 20;
      this.spinner.instance.mode = "indeterminate";
      this.renderer.appendChild(
        this.matButton._elementRef.nativeElement,
        this.spinner.instance._elementRef.nativeElement
      );
    }
  }

  private destroySpinner(): void {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }
}
