import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IAppResponse } from "src/app/portal/core/models/application";
import { environment } from "src/environments/environment";
import { IPermissions, IPermissionsResponse } from "../interfaces/permissions";
import { IHttpDatumResponse, IMapper } from "../interfaces/req-res";

@Injectable({
	providedIn: "root",
})
export class UserPermitsService
	implements IMapper<IPermissionsResponse, IPermissions>
{
	private basePath = `${environment.urlBackendPortal}/${environment.slugBackend}`;

	constructor(private http: HttpClient) {}

	recordLogUserToSession() {
		return this.http.get(`${this.basePath}/log/registrar-log-login`);
	}

	findPermissionByUserLogged(): Observable<
		IHttpDatumResponse<IPermissions[]>
	> {
		return this.http
			.get<IHttpDatumResponse<IPermissionsResponse[]>>(
				`${this.basePath}/listar-permisos-usuarios`
			)
			.pipe(
				map((response) => ({
					data: this.mapperList(response.data),
				}))
			);
	}

	mapperList(dataList: IPermissionsResponse[]): IPermissions[] {
		return dataList.map((be) => ({
			name: be.per_name?.toUpperCase(),
			description: be.description?.toUpperCase(),
		}));
	}
}
