import { environment } from "src/environments/environment";
import { SLUG_PORTAL_INICIO } from "./portal/core/data/slug-routes";

/**
 *
 * NOMBRES DE RUTAS PARA MODULOS
 *
 * **/
export const SLUG_PORTAL = "portal";
export const SLUG_LOGIN = "login";
export const SLUG_DASHBOARD_IPT = "dashboard-ipt";
export const SLUG_GESTION_POLITICAS = "gestion-politicas";
export const SLUG_INITIAL_TUNING = "initial-tuning";
export const SLUG_SITE_ACCEPTANCE = "it/confirmar-sitios/:validationId";
export const SLUG_INVENTORY = "inventario";
export const SLUG_LOW_FLOW = "flujo";
export const SLUG_AUTO_CORE = "automation-core";
export const SLUG_PROCESAR_RECIBOS = "procesar-recibos-luz";
export const SLUG_INTEGRATION = "integration";
export const SLUG_CAPACITACION_TECNICOS = "capacitacion-tecnicos";
export const SLUG_LICENSES = "licenses";

/**
 * REDIRECCIONES
 */
export const PATH_REDIRECT_TO = `/${SLUG_PORTAL}/${SLUG_PORTAL_INICIO}`;
export const PATH_ROUTE_LOGIN = `/${SLUG_LOGIN}`;

/**
 * RUTAS PARA AZURE
 */
export const AZURE_URI_REDIRECT_TO = `${environment.urlClientApp}${PATH_REDIRECT_TO}`;
export const AZURE_URI_POST_LOGOUT_REDIRECT_TO = `${environment.urlClientApp}/${SLUG_LOGIN}`;
export const AZURE_PATH_LOGIN_FAILURE = PATH_ROUTE_LOGIN;
