import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BotUsuario } from 'src/app/bot-telegram/core/models/BotUsuario';
import { BackEndBotService } from 'src/app/bot-telegram/core/services/back-end-bot.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  
  listUsuarios: BotUsuario[] = [];
  titulo = 'Crear Usuario'.toUpperCase();
  usuario: BotUsuario;

  panelCrear = false;
  panelVer = true;

  crear(crear : boolean){
    this.panelCrear = true;
    this.panelVer = false;
    if(crear){
      this.titulo = 'Crear Usuario'.toUpperCase();
      this.usuario = new BotUsuario;
    }
  }

  listar(panelVer : boolean){
    if (panelVer) {
      this.panelCrear = false;
      this.panelVer = true;
      this.cargarUsuarios();
    }
  }

  displayedColumns: string[] = ['id', 'firstName', 'lastName', 'rol', 'correo', 'editar', 'eliminar'];
  dataSource!: MatTableDataSource<any>; // Operador de not null -> !

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private _backEndBotService: BackEndBotService, private _snackBar: MatSnackBar) {
    this.usuario = new BotUsuario;
  }

  ngOnInit(): void {
    this.cargarUsuarios();
  }


  // Acciones
  cargarUsuarios() {
    this._backEndBotService.obtenerUsuarios().subscribe(data => {
      this.listUsuarios = data;
      this.dataSource = new MatTableDataSource(this.listUsuarios);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  borrarUsuarioButton(element: BotUsuario) {
    this._backEndBotService.borrarUsuario(element).subscribe(() => {
      this.cargarUsuarios();
      this.msgBorradoExitoso();
    });
  }

  editarUsuarioButton(element: BotUsuario) {
    this.usuario = element;
    this.crear(false);
  }


  // Funciones extra
  // Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  msgBorradoExitoso(){
    this._snackBar.open('Usuario eliminado con éxito','', {
      duration: 4000,
      horizontalPosition: 'start',
      verticalPosition:'bottom',
    })
  }

}
