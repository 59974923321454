type PropsToastObserve = {
	textLoading?: string;
	textSuccess?: string;
	textError?: string;
};

const toastPropsInitialValues: PropsToastObserve = {
	textLoading: "Cargando datos...",
	textSuccess: "Tarea completada",
	textError: "No se pudo realizar la tarea",
};

export const HOT_TOAST_OBSERVE = (
	props: PropsToastObserve = toastPropsInitialValues
) => ({
	loading: props.textLoading,
	success: props.textSuccess,
	error: props.textError,
});

export const HOT_TOAST_OBSERVE_DOWNLOAD: any = {
	loading: "Descargando archivo...",
	success: "Descarga completada!",
	error: "No se puede descargar el archivo",
};

export const MESSAGE_ERROR_SERVER =
	"Lo sentimos, No se pudo recuperar datos del servidor";
export const MESSAGE_ERROR_ACTION =
	"Lo sentimos, no se puede realizar la acción";

export const MENSAJE = {
	INVALID_FORM:
		"Formulario invalido, por favor complete los campos necesarios",
	CONFIRM_CHANGES: "Esta seguro de confirmar cambios",
};

export const MESSAGE_HTML_ERROR_ACCESS = () => {
	return `
		<div class='error-message-access'>
			<h3>Errores de Acceso</h3>
			<ul class='m-0'>
				<li class='mb-1'>El usuario no tiene los permisos</li>
				<li class='mb-1'>La aplicación no tiene una ruta asignada valida</li>
			</ul>
		</div>
	`;
};
