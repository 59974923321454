import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IPermissions, TypeUserPermissions } from "../interfaces/permissions";

const httpOptions = {
	headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private _userPermissions = new BehaviorSubject<TypeUserPermissions>([]);
	private _permissions = new BehaviorSubject<IPermissions[]>([]);

	userPermissions = this._userPermissions.asObservable();
	permissions = this._permissions.asObservable();
	
	constructor() {}

	// with value
	getUserPermissions() {
		return this._userPermissions.value;
	}

	// with getValue
	getValueUserPermissions() {
		return this._userPermissions.getValue();
	}

	setUserPermissions(permissions: TypeUserPermissions) {
		this._userPermissions.next(permissions);
	}

	checkUserPermissions(possiblePermissions: TypeUserPermissions): boolean {
		// si el array es empty entonces tiene acceso al recurso
		if (!possiblePermissions.length) return true;

		return possiblePermissions.some((permission) =>
			this._userPermissions.value.includes(permission)
		);
	}

	/**
	 * Guarda los permisos filtrados como objeto IPermissions
	 * @param permissions {IPermissions}
	 */
	setUserPermissionsAsObject(permissions: IPermissions[]) {
		this._permissions.next(permissions);
	}

	// with value
	getPermissions() {
		return this._permissions.value;
	}

	// with getValue
	getValuePermissions() {
		return this._permissions.getValue();
	}
}
